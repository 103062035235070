import React from "react";
import { Link } from "gatsby";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { useSite } from "../../hooks/use-site";

export default function Footer() {
    const site = useSite();

    return (
        <footer className="relative overflow-hidden">
            <div
                aria-hidden="true"
                className="flex absolute -top-48 left-0 -z-[1]"
            >
                <div className=" bg-purple-200 opacity-30 blur-3xl w-screen h-[600px] dark:bg-purple-900 dark:opacity-20"></div>
                {/* <div className=" bg-slate-200 opacity-90 blur-3xl w-[577px] h-[300px] transform translate-y-32 dark:bg-slate-800/60"></div> */}
            </div>
            <div className="max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
                <div className="grid grid-cols-1 text-center md:text-left md:grid-cols-4 items-center gap-5 ">
                    <div>
                        <Link
                            className="flex-none text-5xl font-semibold dark:text-white font-norican text-purple-700"
                            to="/"
                            aria-label="9Steps."
                        >
                            9s.
                        </Link>
                    </div>

                    <ul className="text-center col-span-1 md:col-span-2">
                        {site.footer.navigation.map((item) =>
                            item.urlType === "uri" ? (
                                <li
                                    key={item.text}
                                    className="inline-block relative pr-8 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-3 before:-translate-y-1/2 before:content-['/'] before:text-gray-300 dark:before:text-gray-600"
                                >
                                    <Link
                                        className="inline-flex gap-x-2 p-3 lg:py-0 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-500 dark:hover:text-gray-200"
                                        to={item.url}
                                    >
                                        <span className="capitalize text-gray-700 hover:text-black dark:text-secondary dark:hover:text-white">
                                            {" "}
                                            {item.text}{" "}
                                        </span>
                                    </Link>
                                </li>
                            ) : (
                                <li
                                    key={item.text}
                                    className="inline-block relative pr-8 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-3 before:-translate-y-1/2 before:content-['/'] before:text-gray-300 dark:before:text-gray-600"
                                >
                                    <a
                                        className="inline-flex gap-x-2 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-500 dark:hover:text-gray-200"
                                        href={item.url}
                                        title={item.text}
                                        sr-only
                                    >
                                        <span className="capitalize text-gray-700 hover:text-black dark:text-secondary dark:hover:text-white">
                                            {" "}
                                            {item.text}{" "}
                                        </span>
                                    </a>
                                </li>
                            )
                        )}
                    </ul>

                    <div className="md:text-right space-x-2">
                        <a
                            className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:text-gray-800 dark:text-secondary dark:hover:text-white hover:bg-primary/40 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:hover:bg-gray-500 dark:focus:ring-offset-slate-900"
                            href={site?.socialMediaLinks?.facebook || "#!"}
                            title="Facebook"
                            target={"_blank"}
                        >
                            <FaFacebook className=" w-5 h-5" />
                            <span className="sr-only"> Facebook </span>
                        </a>
                        <a
                            className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:text-gray-800 dark:text-secondary dark:hover:text-white hover:bg-primary/40 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:hover:bg-gray-500 dark:focus:ring-offset-slate-900"
                            href={site?.socialMediaLinks?.whatsapp || "#!"}
                            title="WhatsApp"
                            target={"_blank"}
                        >
                            <IoLogoWhatsapp className="w-5 h-5" />
                            <span className="sr-only"> WhatsApp </span>
                        </a>
                        <a
                            className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:text-gray-800 dark:text-secondary dark:hover:text-white hover:bg-primary/40 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:hover:bg-gray-500 dark:focus:ring-offset-slate-900"
                            href={site?.socialMediaLinks?.linkedin || "#!"}
                            title="LinkedIn"
                            target={"_blank"}
                        >
                            <FaLinkedinIn className="w-5 h-5" />
                            <span className="sr-only"> LinkedIn </span>
                        </a>
                        <a
                            className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:text-gray-800 dark:text-secondary dark:hover:text-white hover:bg-primary/40 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:hover:bg-gray-500 dark:focus:ring-offset-slate-900"
                            href={site?.socialMediaLinks?.twitter || "#!"}
                            title="Twitter"
                            target={"_blank"}
                        >
                            <FaTwitter className="w-5 h-5" />
                            <span className="sr-only"> Twitter </span>
                        </a>
                    </div>
                </div>
            </div>
            <p className="text-center max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto text-gray-700 dark:text-secondary">
                © 2023 <span className={"font-norican"}>9Steps</span> A Leading
                Search Engine Optimization Agency. Our Services that Get
                Rankings!
            </p>
        </footer>
    );
}
