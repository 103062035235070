import { graphql, useStaticQuery } from "gatsby";

export const useSite = () => {
    const data = useStaticQuery(graphql`
        query {
            sanitySite {
                title
                siteUrl
                phone
                email
                about
                socialMediaLinks {
                    whatsapp
                    twitter
                    linkedIn
                    instagram
                    facebook
                }
                header {
                    navigation {
                        text
                        url
                        urlType
                    }
                }
                footer {
                    navigation {
                        text
                        url
                        urlType
                    }
                }
            }
        }
    `);

    return data.sanitySite;
};
