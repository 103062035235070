exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-free-seo-report-jsx": () => import("./../../../src/pages/free-seo-report.jsx" /* webpackChunkName: "component---src-pages-free-seo-report-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pre-builds-index-jsx": () => import("./../../../src/pages/pre-builds/index.jsx" /* webpackChunkName: "component---src-pages-pre-builds-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-smart-seo-tools-index-jsx": () => import("./../../../src/pages/smart-seo-tools/index.jsx" /* webpackChunkName: "component---src-pages-smart-seo-tools-index-jsx" */),
  "component---src-template-service-jsx": () => import("./../../../src/template/service.jsx" /* webpackChunkName: "component---src-template-service-jsx" */)
}

