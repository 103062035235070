import React from "react";
import Header from "./header";
import Footer from "./footer";

export default function Index({ children }) {
    // const data = useStaticQuery(graphql`
    //     query {
    //         sanityHeader {
    //             logo
    //             realPhoneNumber
    //             displayPhoneNumber
    //             links {
    //                 name
    //                 link
    //             }
    //         }
    //         sanityFooter {
    //             logo
    //             copyright
    //             links {
    //                 name
    //                 link
    //             }
    //             socialLinks {
    //                 link
    //                 logo {
    //                     asset {
    //                         url
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // `);
    // console.log(data);
    return (
        <div>
            {process.env.GATSBY_BRANCH !== 'master' && (
                <p className={'bg-orange-400 text-orange-900 p-2 font-bold text-center'}>Note! You're visiting our beta versions of the
                    website. <a href={'https://9steps.org'} rel={'nofollow'} className={'underline'}>Please click here to visit live site</a>.
                </p>
            )}
            <Header
            // data={data.sanityHeader}
            ></Header>
            <div>{children}</div>
            <Footer
            //  data={data.sanityFooter}
            />
        </div>
    );
}
