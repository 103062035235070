import React from "react";
import Layout from "./src/components/layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/styles/global.css";
import "preline";

export const wrapPageElement = ({ element, props }) => {
    console.clear();
    return <Layout {...props}>{element}</Layout>;
};
